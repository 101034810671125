import { jwtDecode } from "jwt-decode"
import { v4 as uuidv4 } from "uuid"
import { closedAreaContent } from "./content"

export const getTokenData = token => {
  const decoded = jwtDecode(token)
  return decoded
}

/**
 *
 * Generate dashboard content data based on user role and/or other user properties.
 *
 * @param {Object} user - The user object.
 * @returns {Object[]} An array containing the data for each card to be displayed on the dashboard.
 * Each object in the array represents one card's data.
 *
 * TODO: Replace the Lorem ipsum with actual text
 *
 * Some of the links generated within the dashboard content are user specific,
 * hence the user parameter is required for correctly generating these dynamic links.
 */
export function getDashboardCardsData(user) {
  if (!user) return []

  const commonData = {
    background_color: "green",
    add_class: "m-CardRow--cta",
    card_row_related: true,
  }

  const newsletterData = {
    ...commonData,
    headline: "Meine Newsletter Abos",
    collection: [
      {
        uid: uuidv4(),
        type: "cta",
        icon: "taktik-spielanalyse",
        headline: "Newsletter und MEV",
        button: {
          label: "Zur Auswahl",
          href: "https://newsletter.dfb.de/anmelden?opts%5Buiversion%5D=dfb-ui",
          externalLink: true,
        },
      },
    ],
  }

  const manageFanclubData = {
    ...commonData,
    headline: "Fan Club verwalten",
    collection: [
      {
        uid: uuidv4(),
        type: "cta",
        icon: "association",
        headline: "Familienmitgliedschaft verwalten",
        button: {
          label: "Mitgliedschaft verwalten",
          href: "https://fanclub-dienste.dfb.de/app/verwaltung/familienkarte",
          externalLink: false,
        },
      },
      {
        uid: uuidv4(),
        type: "cta",
        icon: "association",
        headline: "Gruppenmitgliedschaft verwalten",
        button: {
          label: "Mitgliedschaft verwalten",
          href: "https://fanclub-dienste.dfb.de/app/verwaltung/gruppenkarte",
          externalLink: false,
        },
      },
      {
        uid: uuidv4(),
        type: "cta",
        icon: "menschenrechte",
        headline: "Datenauskunft",
        button: {
          label: "Zur Übersicht",
          href: "https://fanclub-dienste.dfb.de/app/verwaltung/dsgvo-auskunft",
          externalLink: false,
        },
      },
      {
        uid: uuidv4(),
        type: "cta",
        icon: "association",
        headline: "Mitgliedschaft kündigen",
        button: {
          label: "Mitgliedschaft kündigen",
          href: "https://fanclub-dienste.dfb.de/app/kuendigung",
          externalLink: true,
        },
      },
    ],
  }

  const userAccountData = {
    ...commonData,
    headline: "Benutzerkonto",
    collection: [
      {
        uid: uuidv4(),
        type: "cta",
        icon: "association",
        headline: "Stammdaten ändern",
        button: {
          label: "Stammdaten ändern",
          href: "https://account.dfb.de/personal-data",
          externalLink: true,
        },
      },
      {
        uid: uuidv4(),
        type: "cta",
        icon: "vielfalt",
        headline: "Passwort ändern",
        button: {
          label: "Passwort ändern",
          href: "https://account.dfb.de/change-password",
          externalLink: true,
        },
      },
      {
        uid: uuidv4(),
        type: "cta",
        icon: "Menschenrechte",
        headline: "Verwaltung Multi-Faktor-Authentifizierung **",
        button: {
          label: "MFA verwalten",
          href: "https://account.dfb.de/otp",
          externalLink: true,
        },
      },
      {
        uid: uuidv4(),
        type: "cta",
        icon: "Fan",
        headline: "Benutzerkonto löschen",
        button: {
          label: "Konto löschen",
          href: "https://account.dfb.de",
          externalLink: true,
        },
      },
    ],
  }

  const memberAccountData = {
    ...commonData,
    headline: "Benutzerkonto",
    collection: [
      {
        uid: uuidv4(),
        type: "cta",
        icon: "association",
        headline: "Stammdaten ändern",
        button: {
          label: "Stammdaten ändern",
          href: "https://fanclub-dienste.dfb.de/app/verwaltung/stammdaten",
          externalLink: true,
        },
      },
      {
        uid: uuidv4(),
        type: "cta",
        icon: "vielfalt",
        headline: "Passwort ändern",
        button: {
          label: "Passwort ändern",
          href: "https://account.dfb.de/change-password",
          externalLink: true,
        },
      },
      {
        uid: uuidv4(),
        type: "cta",
        icon: "Menschenrechte",
        headline: "MFA - Verwaltung",
        button: {
          label: "MFA verwalten",
          href: "https://account.dfb.de/otp",
          externalLink: true,
        },
      },
    ],
  }

  if (
    !user.roles.includes(closedAreaContent.user.roles.api.member) &&
    !user.roles.includes(closedAreaContent.user.roles.api.representative)
  ) {
    return [newsletterData, userAccountData]
  }

  if (
    user.roles.includes(closedAreaContent.user.roles.api.member) &&
    !user.roles.includes(closedAreaContent.user.roles.api.representative)
  ) {
    return [
      {
        ...commonData,
        headline: "Fan Club",
        collection: [
          {
            uid: uuidv4(),
            type: "cta",
            icon: "Fan",
            headline: "Deine Fan Club Betreuer",
            button: {
              label: "Zur Auswahl",
              href: "/app/fanclub/betreuer/",
              externalLink: false,
            },
          },
          {
            uid: uuidv4(),
            type: "cta",
            icon: "Innovations",
            headline: "Fan Club Reisen",
            button: {
              label: "Zu den Reisen",
              href: "/app/fanclub/reisen/",
              externalLink: false,
            },
          },
          {
            uid: uuidv4(),
            type: "cta",
            icon: "Fan",
            headline: "Fan Club Events",
            button: {
              label: "Zu den Events",
              href: "/fans/fanarbeit-nationalmannschaften/fanclub/fan-club-aktiv/",
              externalLink: false,
            },
          },
          {
            uid: uuidv4(),
            type: "cta",
            icon: "Fan",
            headline: "20 % Rabatt im Fan Shop",
            description:
              "Als Fan Club Mitglied bekommst du mit dem CODE: DFB20FANCLUB im Fanshop 20% Rabatt (bis zum 31. Dezember 2024 gültig).",

            button: {
              label: "Zum Fanshop",
              href: "https://www.dfb-fanshop.de/de/",
              externalLink: true,
            },
          },
          {
            uid: uuidv4(),
            type: "cta",
            icon: "Fan",
            headline: "Vorverkauf im Ticket Shop",
            button: {
              label: "Tickets sichern",
              href: "https://tickets.dfb.de/",
              externalLink: true,
            },
          },
          {
            uid: uuidv4(),
            type: "cta",
            icon: "Fan",
            headline: "Bonuspunkte",
            button: {
              label: "Zur Übersicht",
              href: "https://fanclub-dienste.dfb.de/app/verwaltung/bonuspunkte/",
              externalLink: true,
            },
          },
        ],
      },
      manageFanclubData,
      newsletterData,
      memberAccountData,
    ]
  }

  if (user.roles.includes(closedAreaContent.user.roles.api.representative)) {
    return [
      {
        ...commonData,
        headline: "Fan Club",
        collection: [
          {
            uid: uuidv4(),
            type: "cta",
            icon: "Fan",
            headline: "Fan Club Events",
            button: {
              label: "Zu den Events",
              href: "/fans/fanarbeit-nationalmannschaften/fanclub/fan-club-aktiv",
              externalLink: false,
            },
          },
          {
            uid: uuidv4(),
            type: "cta",
            icon: "Fan",
            headline: "20 % Rabatt im Fan Shop",
            description:
              "Als Fan Club Mitglied bekommst du mit dem CODE: DFB20FANCLUB im Fanshop 20% Rabatt (bis zum 31. Dezember 2024 gültig).",
            button: {
              label: "Zum Fanshop",
              href: "https://www.dfb-fanshop.de/de/",
              externalLink: true,
            },
          },
          {
            uid: uuidv4(),
            type: "cta",
            icon: "Fan",
            headline: "Vorverkauf im Ticket Shop",
            button: {
              label: "Tickets sichern",
              href: "https://tickets.dfb.de/",
              externalLink: true,
            },
          },
          {
            uid: uuidv4(),
            type: "cta",
            icon: "Fan",
            headline: "Bonuspunkte",
            button: {
              label: "Zur Übersicht",
              href: "https://fanclub-dienste.dfb.de/app/verwaltung/bonuspunkte/",
              externalLink: true,
            },
          },
        ],
      },
      manageFanclubData,
      {
        ...commonData,
        headline: "Fan Club Betreuer",
        memberCount: {
          label: "Zugeordnete Mitglieder:",
          number: user.representative?.memberCount,
        },
        collection: [
          {
            uid: uuidv4(),
            type: "cta",
            icon: "fan",
            headline: "Mein Betreuer-Profil",
            button: {
              label: "Zur Verwaltung",
              href: `/app/fanclub/betreuer/${user.id}`,
              externalLink: false,
            },
          },
          {
            uid: uuidv4(),
            type: "cta",
            icon: "Innovations",
            headline: "Fan Club Reisen",
            button: {
              label: "Zu den Reisen",
              href: "/app/fanclub/reisen/",
              externalLink: false,
            },
          },
          {
            uid: uuidv4(),
            type: "cta",
            icon: "Fan",
            headline: "Betreuer-Rundschreiben",
            button: {
              label: "Zu den Rundschreiben",
              href: "/app/fanclub/rundschreiben/",
              externalLink: false,
            },
          },
        ],
      },
      newsletterData,
      memberAccountData,
    ]
  }

  return []
}

export const addRepresentative = (id, representatives) => {
  if (!representatives.includes(id)) {
    return [...representatives, id]
  }
  return representatives
}

export const removeRepresentative = (id, representatives) => {
  return representatives.filter(repId => repId !== id)
}

export const getCalendarPage = dateString => {
  if (!dateString) {
    return { calendarHead: "", calendarBody: "-" }
  }
  const months = [
    "Jan",
    "Feb",
    "Mär",
    "Apr",
    "Mai",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Okt",
    "Nov",
    "Dez",
  ]
  const date = new Date(dateString)
  const calendarHead = `${months[date.getMonth()]} ${date.getFullYear()}`
  const calendarBody = date.getDate()

  return { calendarHead, calendarBody }
}

export const isBrowser = typeof window !== "undefined"
